<template>
  <div class="confess global-container">
    <img src="/animations/thumbs_up.gif" alt="Confession Success" />
    <b-button
      variant="primary"
      @click="fetchRandomConfession"
      class="glow-on-hover"
    >
      Read a confession
    </b-button>
  </div>
</template>

<script>
import api from "@/services/api";
export default {
  name: "ConfessionSuccessPage",
  data() {
    return {
      confession: null,
      token: this.$route.query.token || "", // Ensure token is retrieved from route query
    };
  },
  methods: {
    async fetchRandomConfession() {
      if (!this.token) {
        console.error("Token is missing");
        return;
      }
      try {
        console.log(`Fetching confession with token: ${this.token}`);
        const response = await api.get(`/confession?code=${this.token}`);
        console.log("Confession fetched successfully:", response.data);
        this.confession = response.data;
        this.$router.push({
          name: "ReadConfessionPage",
          query: { confession: JSON.stringify(this.confession) },
        });
      } catch (error) {
        console.error("Error fetching random confession:", error);
      }
    },
  },
};
</script>
<style scoped>
.global-container button {
  margin-top: 2rem;
}

@media (min-height: 844px) {
  .global-container img,
  .image-container img {
    max-height: 63vh;
  }
}
</style>
