<template>
  <div v-if="isValid === false" class="invalid-token">
    <img src="/animations/thumbs_down.gif" alt="Invalid Token" />
    <p>
      This token was used at {{ formattedUsedAt }}. Please use a valid token or
    </p>
    <button
      class="btn btn-primary d-inline-flex align-items-center glow-on-hover"
      type="button"
      @click="redirectToPayment"
    >
      Buy another token for €1
    </button>
  </div>
  <div v-else-if="isValid === true">
    <slot></slot>
  </div>
</template>
<script>
import api from "@/services/api";
import { format } from "date-fns";

export default {
  name: "TokenValidator",
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isValid: null,
      usedAt: null,
    };
  },
  async created() {
    await this.validateToken();
  },
  computed: {
    formattedUsedAt() {
      if (!this.usedAt) return "";
      return format(new Date(this.usedAt), "p 'on the' do 'of' MMMM yyyy");
    },
  },
  methods: {
    async validateToken() {
      try {
        const response = await api.get(
          `/confession/validate-token?token=${this.token}`
        );
        if (!response.data.isValid) {
          this.isValid = false;
          this.usedAt = response.data.usedAt;
        } else {
          this.isValid = true;
        }
      } catch (error) {
        console.error("Error validating token:", error);
        this.isValid = false;
      }
    },
    redirectToPayment() {
      this.$router.push("/payment");
    },
  },
};
</script>
<style scoped>
.invalid-token {
  text-align: center;
}

p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

@media (min-height: 844px) {
  .global-container img,
  .image-container img {
    max-height: 60vh;
  }
}
</style>
