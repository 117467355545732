<template>
  <nav
    class="navbar fixed-top navbar-expand-lg navbar-light bg-white border-bottom container"
  >
    <div class="container">
      <div class="d-flex align-items-center">
        <!-- Hamburger Icon for Small Screens -->
        <button
          class="navbar-toggler-icon border-0 bg-white d-lg-none"
          style="width: 2rem; height: 2rem"
          type="button"
          @click="toggleMenu"
          aria-label="Toggle navigation"
        ></button>

        <!-- Title and SVG -->
        <router-link
          to="/"
          class="text-decoration-none d-flex align-items-center flex-grow-1 gap-2 justify-content-center"
        >
          <svg
            class="bi large-screen-only"
            width="45"
            height="36"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5zm13-3H1v2h14zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5"
            />
          </svg>
          <span class="fs-2 fw-bold text-dark">Confession Box</span>
          <svg
            class="bi small-screen-only"
            width="45"
            height="36"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5zm13-3H1v2h14zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5"
            />
          </svg>
          <span class="fs-5 text-muted d-none d-lg-inline"
            >- Get That Nasty Secret Off Your Chest, Read Others'
          </span>
        </router-link>
        <span class="fs-5 text-muted d-none d-lg-inline small-screen-only">
          Get That Nasty Secret Off Your Chest, Read Others'
        </span>
      </div>

      <!-- Collapsible Nav Items -->
      <div
        :class="['collapse navbar-collapse', { show: menuOpen }]"
        id="navbarNav"
      >
        <ul class="navbar-nav ms-auto fs-5">
          <li class="nav-item">
            <router-link
              to="/"
              class="nav-link"
              aria-current="page"
              exact-active-class="active"
              @click.native="closeMenu"
              >Home</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/faq"
              class="nav-link"
              aria-current="page"
              exact-active-class="active"
              @click.native="closeMenu"
              >FAQs</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      this.menuOpen = false;
    },
  },
};
</script>

<style scoped>

svg {
  color: var(--bs-secondary-color);
}

.navbar-nav {
  flex-direction: column;
  align-items: flex-start;
  background: white;
  border-color: var(--bs-secondary-color);
  border-width: 2px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

div.container {
  max-width: 100% !important;
  justify-content: center !important;
}

.d-flex.align-items-center > a {
  flex-direction: row !important;
}

.d-flex {
  flex-direction: column !important;
}

.small-screen-only {
  display: inline-flex !important;
  text-align: center;
}

.large-screen-only {
  display: none !important;
}

#app > nav > div > div.d-flex.align-items-center > span {
  padding-top: 0.5rem;
}

.navbar-toggler {
  position: absolute !important;
  top: 6px;
  left: 6px;
  padding-left: 4px;
  padding-right: 4px;
}

.navbar-toggler-icon {
  position: fixed !important;
  top: 10px;
  left: 10px;
}

#navbarNav > ul {
  position: fixed !important;
  top: 50px;
  left: 10px;
}

button.navbar-toggler {
}

@media (min-width: 992px) {
  .navbar-nav {
    flex-direction: row;
    align-items: center;
  }

  .small-screen-only {
    display: none !important;
  }

  .large-screen-only {
    display: inline-flex !important;
  }

  #navbarNav > ul {
    position: relative !important;
    top: unset;
    left: unset;
  }
}
</style>
