import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store"; 
import BootstrapVue3, { BTooltip } from "bootstrap-vue-3";
import "./assets/global-styles.css";

// Import Bootstrap and BootstrapVue CSS files
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

const app = createApp(App);
app.use(router);
app.use(store); 
app.use(BootstrapVue3);

// Register the tooltip directive globally
app.directive("b-tooltip", BTooltip);

app.mount("#app");
