<template>
  <div class="confess">
    <p class="global-form">{{ confession.content }}</p>
    <b-button variant="primary" @click="buyAnotherToken" class="glow-on-hover"
      >Buy another token</b-button
    >
  </div>
</template>

<script>
export default {
  name: "ReadConfessionPage",  
  data() {
    return {
      confession: {},
    };
  },
  created() {
    const confessionQuery = this.$route.query.confession;
    if (confessionQuery) {
      try {
        this.confession = JSON.parse(confessionQuery);
      } catch (error) {
        console.error("Failed to parse confession query parameter:", error);
      }
    }
  },
  methods: {
    buyAnotherToken() {
      this.$router.push({ name: "PaymentPage" });
    },
  },
};
</script>

<style scoped>
.confess {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.global-form {
  height: fit-content;
  margin-bottom: 2rem;
}
</style>
