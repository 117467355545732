<template>
  <div>
    <b-form @submit.prevent="createConfession" class="global-form">
      <b-form-group label="Title" label-for="title">
        <b-form-input
          id="title"
          v-model="title"
          required
          placeholder="Enter the title of your confession"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Content" label-for="content">
        <b-form-textarea
          id="content"
          v-model="content"
          rows="3"
          required
          placeholder="Type your confession here"
        ></b-form-textarea>
      </b-form-group>
      <div class="email">
        <EmailInput ref="emailInput" @email-valid="handleEmailValid" />
        <b-button
          :disabled="isSubmitDisabled"
          type="submit"
          variant="primary"
          class="w-100 glow-on-hover"
          >Submit</b-button
        >
      </div>
    </b-form>
    <div v-if="errorMessage" class="text-danger mt-2">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import EmailInput from "@/components/General/EmailInput.vue";

export default {
  name: "CreateConfession",  
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  components: {
    EmailInput,
  },
  data() {
    return {
      title: "",
      content: "",
      errorMessage: "",
      isSubmitDisabled: false,
    };
  },
  methods: {
    handleEmailValid(isValid) {
      this.isSubmitDisabled = !isValid;
    },
    async createConfession() {
      const email = this.$refs.emailInput.email;

      if (this.$refs.emailInput.showEmailInput && !this.isSubmitDisabled) {
        this.$refs.emailInput.validateEmail();
        if (this.isSubmitDisabled) {
          return;
        }
      }
      try {
        // Create the confession
        const response = await api.post(`/confession?code=${this.code}`, {
          title: this.title,
          content: this.content,
          email: email,
        });
        console.log("Confession created:", response.data);
        const readToken = response.data.readToken;

        // Redirect to the success page
        this.$router.push({
          name: "ConfessSuccess",
          query: { token: readToken },
        });
      } catch (error) {
        console.error(
          "Error creating confession:",
          error.response ? error.response.data : error
        );
        this.errorMessage = error.response
          ? error.response.data.message
          : "An error occurred while creating the confession.";
      }
    },
  },
};
</script>

<style scoped>
.global-container {
  justify-content: unset !important;
}

.global-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#content {
  min-height: 300px !important;
}

.global-form .mb-3 {
  width: 100% !important;
}

.email {
  width: 285px;
}

@media (min-width: 600px) {
  .global-form {
    width: 500px !important;
  }
}
</style>
