<template>
  <div>
    <b-form @submit.prevent="handleSubmit" class="global-form">
      <div class="d-flex justify-content-between gap-3 align-items-end">
        <div class="w-50">
          <b-form-group label="Number of tokens" label-for="quantity">
            <b-input-group>
              <b-input-group-prepend>
                <b-button
                  class="quantity-button"
                  @click="decrementQuantity"
                  :disabled="quantity <= 1"
                  variant="primary"
                  >-</b-button
                >
              </b-input-group-prepend>
              <b-form-input
                id="quantity"
                v-model="quantity"
                type="number"
                min="1"
                required
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  class="quantity-button"
                  @click="incrementQuantity"
                  variant="primary"
                  >+</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
        <div class="w-50">
          <b-form-group label="Total amount">
            <b-input-group>
              <b-form-input
                v-model="formattedTotalAmount"
                type="text"
                readonly
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </div>
      </div>

      <b-form-group
        label="Email address where you receive the token(s)"
        label-for="email"
      >
        <b-form-input
          id="email"
          v-model="email"
          type="email"
          @input="emailError = ''"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Repeat email address" label-for="repeatEmail">
        <b-form-input
          id="repeatEmail"
          v-model="repeatEmail"
          type="email"
          @blur="checkEmailsMatch"
          required
        ></b-form-input>
      </b-form-group>
      <div v-if="emailMismatch" class="text-danger mb-3">
        Email addresses do not match
      </div>
      <div v-if="emailError" class="text-danger">
        {{ emailError }}
      </div>

      <div id="payment-element"></div>

      <div class="text-center">
        <b-button
          type="submit"
          variant="primary"
          :disabled="loading"
          class="w-100 glow-on-hover"
        >
          Pay {{ formattedTotalAmount }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import api from "@/services/api";
import { detectCurrency, formatPrice } from "@/utils/currency";

export default {
  name: "PaymentForm", 
  data() {
    return {
      quantity: 1,
      amount: 100,
      currency: "usd",
      currencySymbol: "$",
      loading: false,
      email: "",
      repeatEmail: "",
      emailError: "",
      emailMismatch: false,
      paymentElement: null,
      paymentIntentId: null,
    };
  },

  computed: {
    totalAmount() {
      return this.quantity * this.amount;
    },
    stripe() {
      return this.$store.state.stripe;
    },
    elements() {
      return this.$store.state.elements;
    },
    clientSecret() {
      return this.$store.state.clientSecret;
    },
    isInputValid() {
      return (
        this.validateEmail(this.email) &&
        this.email === this.repeatEmail &&
        this.quantity >= 1
      );
    },
    formattedTotalAmount() {
      return formatPrice(this.totalAmount, this.currencySymbol);
    },
  },

  async mounted() {
    try {
      const { currency, currencySymbol } = detectCurrency();
      this.currency = currency;
      this.currencySymbol = currencySymbol;
      this.loading = true;
      // Initialize Stripe
      await this.$store.dispatch("initializeStripe");

      // Create payment intent on page load
      await this.createPaymentIntent();

      // Initialize payment element
      await this.initializePaymentElement();
    } catch (error) {
      console.error("Error in mounted:", error);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    incrementQuantity() {
      this.quantity++;
    },

    decrementQuantity() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },

    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    },

    checkEmailsMatch() {
      this.emailMismatch = this.email !== this.repeatEmail;
    },

    async createPaymentIntent() {
      this.loading = true;
      try {
        const response = await api.post("/confession/create-payment-intent", {
          amount: this.totalAmount,
          currency: this.currency,
          quantity: this.quantity,
        });

        if (!response.data.clientSecret || !response.data.paymentIntentId) {
          throw new Error("Client secret or Payment Intent ID not received");
        }

        this.$store.commit("setClientSecret", response.data.clientSecret);
        this.paymentIntentId = response.data.paymentIntentId;
      } catch (error) {
        console.error("Error creating payment intent:", error);
      } finally {
        this.loading = false;
      }
    },

    async initializePaymentElement() {
      console.log("Initializing payment element"); // Debug statement
      await this.$store.dispatch("setupStripeElements");
      if (this.elements) {
        this.paymentElement = this.elements.create("payment");
        this.paymentElement.mount("#payment-element");
        console.log("Payment element mounted"); // Debug statement
      } else {
        console.error("Stripe elements not initialized");
      }
    },

    async updatePaymentIntent() {
      // Validate user inputs
      if (!this.isInputValid) {
        this.emailError = "Please enter valid and matching email addresses.";
        return;
      }

      try {
        await api.patch(
          `/confession/update-payment-intent/${this.paymentIntentId}`,
          {
            amount: this.totalAmount,
            quantity: this.quantity,
            email: this.email,
          }
        );
      } catch (error) {
        console.error("Error updating payment intent:", error);
      }
    },

    // PaymentForm.vue (Excerpt from handleSubmit)
    async handleSubmit() {
      this.loading = true;
      try {
        // Update the payment intent with the latest details
        await this.updatePaymentIntent();

        const { error } = await this.stripe.confirmPayment({
          elements: this.elements,
          confirmParams: {
            return_url: `${window.location.origin}/payment-success?paymentIntentId=${this.paymentIntentId}`,
            receipt_email: this.email,
          },
        });

        if (error) {
          console.error("Payment failed:", error.message);
          this.emailError = `Payment failed: ${error.message}`;
        }
      } catch (error) {
        console.error("Error confirming payment:", error);
        this.emailError = "Error confirming payment. Please try again.";
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
#payment-element {
  margin-bottom: 1rem;
}
.text-center {
  text-align: center;
}
.quantity-button {
  width: 35px; /* Ensure both buttons have the same width */
  background-color: black; /* Primary color */
  border-color: black; /* Primary color */
}
/* Hide the up and down arrows for number input */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.text-danger {
  color: red;
}
.payment-method-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 1rem;
}
.btn-card {
  background-color: black;
  color: white;
}
.btn-paypal {
  background-color: #ffc439;
  color: black;
}
.btn-apple-pay {
  background-color: black;
  color: white;
}
.btn-google-pay {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.global-form {
  margin-top: 1rem;
}
</style>
