<template>
  <div class="email-input">
    <b-form-group>
      <b-form-checkbox v-model="showEmailInput">
        Anonymously email this confession
      </b-form-checkbox>
    </b-form-group>
    <b-form-group v-if="showEmailInput">
      <b-form-input
        id="email"
        v-model="email"
        type="email"
        placeholder="example@example.com"
        @blur="validateEmail"
      ></b-form-input>
      <div v-if="emailError" class="text-danger mt-2">
        {{ emailError }}
      </div>
      <div v-if="emailSuccess" class="text-success mt-3">
        {{ emailSuccess }}
      </div>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "EmailInput",
  data() {
    return {
      showEmailInput: false,
      email: "",
      emailError: "",
      emailSuccess: "",
    };
  },
  watch: {
    showEmailInput() {
      if (!this.showEmailInput) {
        this.email = "";
        this.emailError = "";
        this.emailSuccess = "";
        this.$emit("email-valid", true);
      }
    },
    email() {
      if (this.showEmailInput) {
        this.validateEmail();
      }
    },
  },
  methods: {
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.email)) {
        this.emailError = "Insert a valid email address";
        this.emailSuccess = "";
        this.$emit("email-valid", false);
      } else {
        this.emailError = "";
        this.emailSuccess = "Valid email address";
        this.$emit("email-valid", true);
      }
    },
  },
};
</script>

<style scoped>
.email-input {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
