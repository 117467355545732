<template>
  <div class="confess">
    <FAQContent />
  </div>
</template>

<script>
import FAQContent from "@/components/FAQ.vue";

export default {
  name: "FAQPage",
  components: {
    FAQContent,
  },
};
</script>

