<template>
  <div class="confess global-container">
    <PaymentForm />
  </div>
</template>

<script>
import PaymentForm from "@/components/PaymentForm.vue";

export default {
  name: "PaymentPage",
  components: {
    PaymentForm,
  },
};
</script>