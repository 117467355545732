<template>
  <div class="confess faq-container">
    <div class="faq-item" v-for="(faq, index) in faqs" :key="index">
      <h3>{{ faq.question }}</h3>
      <p v-html="faq.answer"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQPage",  
  data() {
    return {
      faqs: [
        {
          question: "What is Confession Box?",
          answer:
            "Confession Box is an anonymous platform where users can share their confessions. You can write your confession and optionally send it to an email address anonymously.",
        },
        {
          question: "Is my confession anonymous?",
          answer:
            "Yes, all confessions submitted through Confession Box are anonymous. If you choose to send your confession to an email address, the recipient will not know your identity.",
        },
        {
          question: "How do I submit a confession?",
          answer:
            "After purchasing a token, you will be redirected to the 'Make Confession' page. To submit a confession, simply type your confession in the provided text area on the page and click the 'Submit' button.",
        },
        {
          question: "Can I send my confession to an email address?",
          answer:
            "Yes, you can send your confession to an email address. Check the 'Anonymously email this confession' checkbox and provide a valid email address before submitting your confession.",
        },
        {
          question: "What happens if the email sending fails?",
          answer:
            "If the email sending fails, you will receive an error message indicating that the email could not be sent. You can try again, use a different email address, or submit your confession without sending an email.",
        },
        {
          question:
            "How do I know if my confession was successfully submitted?",
          answer:
            "After submitting your confession, you will be redirected to a success page if the submission was successful. If there was an error, you will see an error message on the submission page.",
        },
        {
          question: "How can I read random confessions?",
          answer:
            "After submitting a confession, you will be asked if you want to read a confession. If you click the button, you will be redirected to the page where you can read a random confession.",
        },
        {
          question: "What should I do if I encounter an error?",
          answer:
            "If you encounter an error, please check the error message for details. You can try again or contact our support team for assistance at <a href='mailto:confessionbox.fun@gmail.com'>confessionbox.fun@gmail.com</a>.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.confess {
  padding: 20px;
}
.faq-container {
  padding-bottom: 4.5rem;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-item h3 {
  margin-bottom: 10px;
}

.faq-item p {
  padding-bottom: 0.75rem;
}
</style>
