<template>
  <div class="global-container">
    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-bs-ride="carousel"
      data-bs-interval="4000"
      ref="carousel"
    >
      <!-- Carousel Indicators -->
      <div class="carousel-indicators">
        <button
          v-for="(slide, index) in slides"
          :key="index"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          :data-bs-slide-to="index"
          :class="getButtonClass(index)"
          :aria-current="index === activeIndex ? 'true' : undefined"
          :aria-label="`Slide ${index + 1}`"
          @click="jumpToSlide(index)"
        ></button>
      </div>

      <!-- Carousel Items -->
      <div class="carousel-inner">
        <div
          v-for="(slide, index) in slides"
          :key="index"
          :class="[
            'carousel-item',
            { active: index === activeIndex },
            { 'slide-2': index === 1 },
          ]"
        >
          <div class="carousel-content">
            <div v-if="slide.image" class="image-container">
              <img :src="slide.image" :alt="slide.alt" width="100%" />
            </div>
            <div v-else v-html="slide.svg"></div>
            <h5 class="slide-text fs-4">{{ getSlideText(slide) }}</h5>
          </div>
        </div>
      </div>

      <!-- Carousel Controls -->
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
        @click="debouncedPrevSlide"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
        @click="debouncedNextSlide"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <button
      class="btn btn-primary d-inline-flex align-items-center glow-on-hover"
      type="button"
      @click="redirectToPayment"
    >
      Buy a token for {{ formatPrice(100, currencySymbol) }}
    </button>
  </div>
</template>

<script>
import { Carousel } from "bootstrap";
import { debounce } from "lodash";
import { detectCurrency, formatPrice } from "@/utils/currency";

export default {
  name: "LandingPage",
  data() {
    return {
      slides: [
        {
          alt: "Slide 1",
          image: "/animations/token.gif",
          svg: `<svg xmlns="http://www.w3.org/2000/svg" width="350" height="350" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z"/>
          </svg>`,
          textKey: "buyToken",
        },
        {
          alt: "Slide 2",
          image: "/animations/redeem.gif",
          svg: `<svg xmlns="http://www.w3.org/2000/svg" width="350" height="350" fill="currentColor" class="bi bi-arrow-return-left" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5"/>
        </svg>`,
          textKey: "redeemToken",
        },
        {
          alt: "Slide 3",
          image: "/animations/freelancer.gif",
          svg: `<svg xmlns="http://www.w3.org/2000/svg" width="350" height="350" fill="currentColor" class="bi bi-chat-right-text" viewBox="0 0 16 16">
          <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
          <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5"/>
        </svg>`,
          textKey: "confessAnonymously",
        },
        {
          alt: "Slide 4",
          image: "/animations/reading_book.gif",
          svg: `<svg xmlns="http://www.w3.org/2000/svg" width="350" height="350" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
          </svg>`,
          textKey: "readConfession",
        },
      ],
      activeIndex: 0,
      interval: 4000,
      currency: "usd", // Default currency
      currencySymbol: "$",
    };
  },
  methods: {
    getButtonClass(index) {
      return {
        active: index === this.activeIndex,
        inactive: index !== this.activeIndex,
      };
    },
    prevSlide() {
      this.pauseCarousel();
      this.activeIndex =
        (this.activeIndex - 1 + this.slides.length) % this.slides.length;
      this.restartCarousel();
    },
    nextSlide() {
      this.pauseCarousel();
      this.activeIndex = (this.activeIndex + 1) % this.slides.length;
      this.restartCarousel();
    },
    jumpToSlide(index) {
      this.pauseCarousel();
      this.activeIndex = index;
      this.restartCarousel();
    },
    pauseCarousel() {
      const carousel = this.$refs.carousel;
      if (carousel) {
        carousel.carouselInstance.pause();
      }
    },
    restartCarousel() {
      const carousel = this.$refs.carousel;
      if (carousel) {
        carousel.carouselInstance.cycle();
      }
    },
    redirectToPayment() {
      this.$router.push("/payment");
    },
    formatPrice() {
      {
        return formatPrice(100, this.currencySymbol);
      }
    },
    getSlideText(slide) {
      const textTemplates = {
        buyToken: `Buy a token for ${formatPrice(100, this.currencySymbol)}`,
        redeemToken: "Redeem your token",
        confessAnonymously: "Anonymously confess",
        readConfession: "Read a confession",
      };
      return textTemplates[slide.textKey] || "";
    },
  },
  mounted() {
    const { currency, currencySymbol } = detectCurrency();
    this.currency = currency;
    this.currencySymbol = currencySymbol;

    const carouselElement = this.$refs.carousel;
    if (carouselElement) {
      carouselElement.carouselInstance = new Carousel(carouselElement, {
        interval: this.interval,
      });
    }
  },
  created() {
    this.debouncedPrevSlide = debounce(this.prevSlide, 300);
    this.debouncedNextSlide = debounce(this.nextSlide, 300);
  },
};
</script>

<style scoped>
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-item-next.carousel-item-start,
.carousel-item-prev.carousel-item-end {
  transform: translateX(0);
}

.carousel-indicators {
  bottom: -60px;
}

.carousel-indicators button {
  width: 30px;
  height: 5px;
  border-radius: 0;
  background-color: rgba(33, 37, 41, 0.75);
}

.carousel-indicators .active {
  background-color: black;
}

.carousel-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e");
}

.btn-primary {
  margin-top: 5rem;
}

/* Add smooth transition for all carousel animations */
.carousel {
  perspective: 1000px;
}

.carousel-inner > * {
  transition: all 0.6s ease-in-out;
}
</style>
