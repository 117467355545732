<template>
  <div class="confess global-container">
    <TokenValidator :token="token">
      <CreateConfession :code="token" />
    </TokenValidator>
  </div>
</template>

<script>
import CreateConfession from "@/components/CreateConfession.vue";
import TokenValidator from "@/components/TokenValidator.vue";

export default {
  name: "MakeConfessionPage",
  components: {
    CreateConfession,
    TokenValidator,
  },
  data() {
    return {
      token: this.$route.query.token || "",
    };
  },
};
</script>
