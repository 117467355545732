<template>
  <div id="app">
    <NavBar />
    <b-container class="mt-5 pt-5 bg-white">
      <b-row>
        <b-col>
          <h1 class="header text-center mb-4">
            {{ title }}
          </h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <router-view></router-view>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import NavBar from "@/components/General/NavBar.vue";
import Footer from "@/components/General/Footer.vue";

const title = ref("Default Title");
const route = useRoute();

const updateTitle = (newTitle) => {
  title.value = newTitle || "Default Title";
};

watch(
  () => route.meta.title,
  (newTitle) => {
    updateTitle(newTitle);
  },
  { immediate: true }
);
</script>

<style>
@import "bootstrap/dist/css/bootstrap.css";
@import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

.header {
  position: relative;
  z-index: 1000;
}

img,
svg {
  vertical-align: middle;
}

@media (max-width: 991px) {
  #app > div {
    padding-top: 2rem !important;
  }
}

@media (max-width: 447px) {
  #app > div {
    padding-top: 2.75rem !important;
  }
}

</style>
