import { createStore } from "vuex";
import { loadStripe } from "@stripe/stripe-js";

export default createStore({
  state: {
    stripe: null,
    elements: null,
    clientSecret: null,
  },
  mutations: {
    setStripe(state, stripe) {
      state.stripe = stripe;
    },
    setElements(state, elements) {
      state.elements = elements;
    },
    setClientSecret(state, clientSecret) {
      state.clientSecret = clientSecret;
    },
  },
  actions: {
    async initializeStripe({ commit }) {
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      commit("setStripe", stripe);
    },
    async setupStripeElements({ state, commit }) {
      if (!state.stripe) {
        await this.dispatch("initializeStripe");
      }
      const appearance = { theme: "stripe" };
      const elements = state.stripe.elements({
        appearance,
        clientSecret: state.clientSecret,
      });
      commit("setElements", elements);
    },
  },
});