<template>
  <div class="confess global-container">
    <RandomConfession />
  </div>
</template>

<script>
import RandomConfession from "@/components/RandomConfession.vue";

export default {
  name: "ReadConfessionPage",
  components: {
    RandomConfession,
  },
};
</script>

