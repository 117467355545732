<template>
  <div class="payment-success global-container">
    <img
      src="/animations/Card_Payment_Successful.gif"
      alt="Payment Successful"
    />
    <p>You will be redirected shortly.</p>
    <p>We have also emailed the token(s) to the email address you provided</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PaymentSuccessPage", 
  mounted() {
    // Extract payment_intent ID from the URL query parameters
    const paymentIntentId = this.$route.query.payment_intent;
    console.log(
      `PaymentIntentId received in query parameters: ${paymentIntentId}`
    );

    if (paymentIntentId) {
      // Make an API call to your backend to retrieve the token
      axios
        .post(`${process.env.VUE_APP_API_URL}/confession/get-token`, {
          paymentIntentId,
        })
        .then((response) => {
          const token = response.data.token;
          if (token) {
            console.log(`Token retrieved from backend: ${token}`);
            // Redirect to /confess with the token after a delay
            setTimeout(() => {
              this.$router.push({ path: "/confess", query: { token } });
            }, 4000); // Redirect after 4 seconds
          } else {
            console.error("No token found in backend response.");
          }
        })
        .catch((error) => {
          console.error("Error fetching token from backend:", error);
        });
    } else {
      console.error("No paymentIntentId found in query parameters.");
    }
  },
};
</script>

<style scoped>
.payment-success {
  text-align: center;
}

p {
  font-size: 1.25rem;
}

@media (min-height: 844px) {
  .image-container img,
  .global-container img {
    max-height: 63vh;
  }
}
</style>
