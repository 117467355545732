// src/utils/currency.js

export function detectCurrency() {
  //const locale = navigator.language || navigator.userLanguage;
  let currency = "usd";
  let currencySymbol = "$";

  /* if (locale.startsWith("en-US")) {
    currency = "usd";
    currencySymbol = "$";
  } else if (locale.startsWith("en-GB")) {
    currency = "gbp";
    currencySymbol = "£";
  } else if (locale.startsWith("en-AU")) {
    currency = "aud";
    currencySymbol = "A$";
  } else {
    // Default to USD
    currency = "usd";
    currencySymbol = "$";
  } */

  console.log(`Currency set to: ${currency} (${currencySymbol})`);
  return { currency, currencySymbol };
}

export function formatPrice(amount, currencySymbol) {
  return `${currencySymbol}${(amount / 100).toFixed(0)}`;
}
